/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/RootReducers';
import { Dispatch } from 'redux';
import { PostPaymentTokenResponse } from '../../services/payment/postPaymentToken/PostPyamentToken.data';
import {
  PaymentProvider,
  Property,
  RentalFrequency,
} from '../../services/rentService/getRentService/GetRentServiceResponse.data';
import { PostRentServiceRequest } from '../../services/rentService/postRentService/PostRentService.data';
import { RentServiceActions } from '../../store/actions/RentServiceActions';
import { routes } from '../../Routes';
import { History } from 'history';
import { styles, SummaryStyles } from './SummaryStyles';
import { handlePaymentDetails } from './SummaryUtils';
import { calculateCost } from '../../helper/CostHelper';
import { LABELS } from './SummaryConstants';
import LOGO_HOME from '../../assets/images/homescreen/home.png';
import cardLogo from '../../assets/credit-card-24-px.svg';
import house from '../../assets/houses/house3.png';
import NavBar from '../../component/navBar/NavBar';
import { fetchAgencyLogo } from '../../helper/AgencyLogoHelper';
import {
  paymentMethod,
  paymentTypes,
} from '../../component/wallet/savedPaymentMethods/SavedPaymentMethodsConstants';
import { rentDefaultServiceTypes } from '../setup/SetupConstants';
import MainContainer from '../../component/mainContainer/MainContainer';
import { useGetLeaseData } from '../../helper/RentServiceHelper';
import { getPaymentType } from '../property/ConfirmationConstants';
import { AppSettings } from '../../AppSettings';
import { sortedWebUrls } from '../../SortedWebUrls';
import { store } from '../../store/Store';
import { AGENCIES, APP } from '../../helper/AppNameHelper';

export interface PaymentMethodType {
  paymentTypeLogo: string;
  secondaryText: string;
}

interface SummaryProps extends SummaryStyles, RouteComponentProps<{}> {
  paymentResponse: PostPaymentTokenResponse;
  postRentService: (
    data: PostRentServiceRequest,
    history: History,
    propertyId: string,
    property: Property | null | undefined,
    cimetEnabled?: boolean | null,
  ) => void;
}

const Summary: FC<SummaryProps> = ({ classes, history, paymentResponse, postRentService }) => {
  const lease = useGetLeaseData();
  const {
    paymentDetails,
    property,
    paymentFrequency,
    monthlyCost,
    serviceability,
    leaseRefId,
    agency,
    nextPaymentDate,
    initialPayments,
    cimetEnabled,
  } = lease!;
  const { paymentProvider, billInfo, paymentReferenceId } = paymentDetails!;
  const { unitNumber, streetNumber, streetName, state, suburb, postcode } = property!;
  const address1 = `${unitNumber ? `${unitNumber}/` : ''}${streetNumber || ''} ${streetName}`;
  const address2 = `${suburb}, ${state} ${postcode}`;
  const calculatedCost = calculateCost(
    paymentFrequency as RentalFrequency,
    monthlyCost as number,
    true,
  );

  const selectedPaymentMethod =
    !!initialPayments.length || paymentProvider === PaymentProvider.SORTED
      ? handlePaymentDetails(paymentResponse)
      : undefined;

  const handlePostRentService = () => {
    if (serviceability && serviceability.length === 0) {
      if (
        !!initialPayments.length ||
        (paymentProvider && paymentProvider === PaymentProvider.SORTED)
      ) {
        postRentService(
          {
            paymentRefId: paymentResponse.refId,
            leaseRefId: leaseRefId,
          },
          history,
          `${property!.id}`,
          property,
          cimetEnabled,
        );
      } else {
        postRentService(
          {
            paymentRefId: null,
            leaseRefId: leaseRefId,
          },
          history,
          `${property!.id}`,
          property,
          cimetEnabled,
        );
      }
    } else {
      history.push(routes.serviceability);
    }
  };

  const renderPaymentProviderLogo = () => {
    return paymentResponse.paymentMethod === paymentTypes.Bank ? (
      <img src={paymentMethod.Bank.logo} className={classes.bankLogo} />
    ) : paymentResponse.cardType === 'Mastercard' ? (
      <img src={paymentMethod.Credit[0].logo} className={classes.masterLogo} />
    ) : paymentResponse.cardType === 'Visa' ? (
      <img src={paymentMethod.Credit[1].logo} className={classes.visaLogo} />
    ) : (
      <img src={cardLogo} className={classes.visaLogo} />
    );
  };

  return (
    <div className={classes.root}>
      <NavBar
        subTitle={!!initialPayments.length ? LABELS.NAV_INITIAL_TITLE : LABELS.NAV_TITLE}
        subText={LABELS.NAV_SUBTITLE}
      />
      <MainContainer>
        <div className={classes.contentContainer}>
          <div className={classes.contentTitle}>{LABELS.PROPERTY}</div>
          <div className={classes.propertyContainer}>
            <div className={classes.messageContainer}>
              <div className={classes.messageTitle}>
                {address1.length > 18 ? `${address1.substring(0, 18)}...` : address1}
              </div>
              <div className={classes.messageSubtitle}>
                {address2.length > 18 ? `${address2.substring(0, 18)}...` : address2}
              </div>
            </div>
            <img
              className={classes.agencyLogo}
              onError={(e) => {
                (e.target as HTMLImageElement).src = fetchAgencyLogo('SORTED')!;
              }}
              src={
                lease && !!lease.agency.logoUrl
                  ? lease.agency.logoUrl
                  : fetchAgencyLogo(lease ? lease.agency.code : 'SORTED')
              }
            />
            <div className={classes.houseContainer}>
              <img className={classes.house} src={house} />
            </div>
          </div>
        </div>
      </MainContainer>
      <MainContainer>
        <div className={classes.contentContainer}>
          <div className={classes.contentTitle}>
            {!!initialPayments.length ? LABELS.INITIAL_PAYMENTS : LABELS.RENT_PAYMENTS}
          </div>
          {!!initialPayments.length ? (
            <>
              {initialPayments.map((payment, idx) => (
                <div className={classes.rentContainer}>
                  <img className={classes.rentLogo} src={LOGO_HOME} />
                  <div className={classes.messageContainer}>
                    <div className={classes.messageTitle}>{getPaymentType(payment)}</div>
                    <div className={classes.messageSubtitle}>{agency.tradingName}</div>
                  </div>
                  <div className={classes.rentTextContainer}>
                    <div className={classes.rentTitle}>${payment.amount.toFixed(2)}</div>
                    <div className={classes.rentSubtitle}>Draw date: {payment.paymentDate}</div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className={classes.rentContainer}>
              <img className={classes.rentLogo} src={LOGO_HOME} />
              <div className={classes.messageContainer}>
                <div className={classes.messageTitle}>{LABELS.RENT}</div>
                <div className={classes.messageSubtitle}>{agency.tradingName}</div>
              </div>
              <div className={classes.rentTextContainer}>
                <div className={classes.rentTitle}>{calculatedCost}</div>
                <div className={classes.rentSubtitle}>{paymentFrequency}</div>
              </div>
            </div>
          )}
          {(!!initialPayments.length ||
            (paymentProvider && paymentProvider === PaymentProvider.SORTED)) && (
            <div className={classes.rentContainer}>
              {renderPaymentProviderLogo()}
              <div className={classes.messageContainer}>
                <div className={classes.paymentTitle}>
                  {paymentResponse.paymentMethod === paymentTypes.Bank
                    ? 'Bank Account'
                    : 'Credit card'}
                </div>
                <div className={classes.messageSubtitle}>
                  {selectedPaymentMethod!.secondaryText.replace(/\./g, '*')}
                </div>
              </div>
            </div>
          )}
          {!initialPayments.length && (
            <div className={classes.extraInfoContainer}>
              {nextPaymentDate && paymentProvider === PaymentProvider.SORTED && (
                <div className={classes.infoRow}>
                  <div className={classes.itemTitle}>Next payment date</div>
                  <div className={classes.itemValue}>{nextPaymentDate}</div>
                </div>
              )}
              {paymentProvider && paymentProvider !== PaymentProvider.SORTED && (
                <div className={classes.infoRow}>
                  <div className={classes.itemTitle}>Payment provider</div>
                  <div className={classes.itemValue}>{paymentProvider}</div>
                </div>
              )}
              {billInfo && (
                <div className={classes.infoRow}>
                  <div className={classes.itemTitle}>Biller code</div>
                  <div className={classes.itemValue}>{billInfo}</div>
                </div>
              )}
              {paymentReferenceId && (
                <div className={classes.infoRow}>
                  <div className={classes.itemTitle}>Reference number</div>
                  <div className={classes.itemValue}>{paymentReferenceId}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </MainContainer>
      <div className={classes.footerButtonsContainer}>
        <MainContainer>
          <div className={classes.footerButton} onClick={() => handlePostRentService()}>
            Finish
          </div>
        </MainContainer>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    paymentResponse: state.payment.postPaymentTokenReponse as PostPaymentTokenResponse,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  postRentService: (
    data: PostRentServiceRequest,
    history: History,
    propertyId: string,
    property: Property | null | undefined,
    cimetEnabled?: boolean | null,
  ) => {
    dispatch(
      RentServiceActions.postRentServiceStart({
        data,
        onSuccess: () => {
          if (APP === AGENCIES.SWITCH) {
            history.push(`${routes.checkout.abandonCart}`);
          } else if (!!cimetEnabled) {
            window.location.href = `${AppSettings.server.sortedWebUrl}${sortedWebUrls.cimet(
              propertyId,
              store.getState().token.accessToken as string,
              store.getState().token.refreshToken as string,
            )}`;
          } else {
            history.push(
              `${
                routes.checkout.select
              }?requiredServiceTypes=${rentDefaultServiceTypes.toString()}&propertyId=${propertyId}`,
            );
          }
        },
      }),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(Summary)));
